












































































import { getLastActiveTeam } from '@/utils/Teams'
import type { QScrollArea, QMenu } from 'quasar'
import { Component, Vue, Ref } from 'vue-property-decorator'
import { loginLogger } from '@/loggers'
import {
  loginStore,
  teamsStore,
  uiSettingsStore,
} from '@/store'
import { transformEntityName } from '@/utils'

@Component({
  name: 'TheAddToTeamView',
  components: {
    ContentHeader: () => import('./components/ContentHeader.vue'),
    BaseInput: () =>
      import(
        '@/components/Modals/Default/TwoFactorAuth/components/BaseInput.vue'
      ),
  },
})
export default class TheAddToTeamView extends Vue {
  @Ref() readonly scroll!: QScrollArea
  @Ref() readonly menu!: QMenu

  loading = false

  /**
   * Array for phone inputs (v-model by index)
   */
  phones = ['', '', '']

  private readonly thumbStyle: Partial<CSSStyleDeclaration> = {
    right: '0px',
    borderRadius: '10px',
    backgroundColor: 'var(--td-gray-4)',
    width: '4px',
    opacity: '1',
  }

  teamId = ''
  teamName = ''

  /**
   * Get current team from store (set current team on prev page!)
   */
  created () {
    const getCurrentTeam = () => {
      try {
        return teamsStore.getters.currentTeam
      } catch {
        loginLogger.log('No current team in store. Defaulting...')
      }
    }

    const getLastActive = () => {
      const lastActive = getLastActiveTeam()
      if (lastActive) {
        loginLogger.log('Defaulted to last active:', lastActive.uid)
        return lastActive
      }
    }

    const getFirstInStore = () => {
      const firstInStore = teamsStore.getters.teams[0]
      if (firstInStore) {
        loginLogger.log('Defaulted to first in store:', firstInStore.uid)
        return firstInStore
      }
    }

    const team = (
      getCurrentTeam() ||
      getLastActive() ||
      getFirstInStore()
    )

    if (!team) return this.$router.push({ name: 'Main' })
    this.teamId = team.uid
    this.teamName = transformEntityName(team.name)
  }

  /**
   * Go to CreateTeam, don't use router.go(-1), cause of EDIT action
   */
  onClickBackArrow () {
    window.goal('onboardingAction', { onboarding: 'Пригласите коллег — «Назад»' })
    this.$router.push({ name: 'CreateTeam', params: { edit: 'edit' } })
  }

  /**
   * Set value to array by index
   * If value is empty string and it's last phone input - remove that input
   * If it's last input - add new input to array
   */
  onPhoneInput (value: string, index: number) {
    if (index === this.phones.length - 2 && !value) {
      this.phones.splice(index, 1)
      return
    }

    if (index === this.phones.length - 1) {
      this.addPhone()
    }
    this.phones.splice(index, 1, value)
  }

  /**
   * Init team and go to next page
   * @todo change moving to UserProfile by Main page after moving Profile to first page of onboarding
   */
  goToTeam (isSubmit?: boolean) {
    if (!isSubmit) {
      window.goal('onboardingAction', { onboarding: 'Пригласите коллег — «Пропустить шаг»' })
    }
    uiSettingsStore.actions.initTeam({ teamId: this.teamId })
    teamsStore.actions.initTeam(this.teamId)
    this.$router.push({ name: 'UserProfile' })
  }

  /**
   * Add many users one by one By phones number
   */
  onSubmit () {
    window.goal('onboardingAction', { onboarding: 'Пригласите коллег — «Продолжить»' })
    this.phones.forEach(() =>
      window.goal('onboardingAction', { onboarding: 'Пригласите коллег — количество введённых телефонов' }))

    try {
      loginStore.actions.addManyToTeam({
        teamId: this.teamId,
        phones: this.phones,
      })
      this.goToTeam(true)
    } catch (e) {
      loginLogger.error('Error with adding contacts')
    }
  }

  /**
   * I don't really know why without pixels it's doesn't work(
   * @see setScrollPercentage and QScrollArea
   */
  async addPhone () {
    this.phones.push('')
    await this.$nextTick()
    this.scroll.setScrollPosition(this.phones.length * 56)
  }
}
